require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/landing/components/da8e859e0d4eea377c833de8f8c5fdeb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('7bd4546faf29576b286421f3b9993be7');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/e978ab7e1322c23275f14baf4f8c5d08/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('7bd4546faf29576b286421f3b9993be7');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/f23b01c97132124aa391eacdf76ad5ee/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('76af0728357c643e23667a44f3b5cdbc', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/ed145ebeedd4b544cf0cd71c797cc18e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://100konkretow.pl/wszystkie-konkrety/', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/f28e8a4ffa5a84fa2d8dcb2a57dd3641/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('535f21f73f32d36d92d1b176e494fc42');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/08d298b14d1a49952e75e1590ec7ecb3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
      return (String(Math.round((((new Date()).valueOf()) - ((new Date('12/13/2023')).valueOf())) / 86400000)))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing/components/dc0dda49a9e23f8e3bad5aeb74456836/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('535f21f73f32d36d92d1b176e494fc42');

  },
  /* handler:onClick */
  /* content */
}))

